import React, {useEffect} from "react";
import _ from "lodash";

import {graphql, navigate} from "gatsby";

import "../css/app.css";
import {getViewModule} from "../util/router";
import {SettingsContext} from "../context/SettingsContext";

const LangSelPage = ({data, ...props}) => {
    const {
        allStrapiEntities: {nodes: rootContainers},
        allStrapiAppSettings,
        allStrapiI18NLocale: {nodes: locales}
    } = data;

    const settings = allStrapiAppSettings?.nodes[0];
    const strapiChildren = _.sortBy(rootContainers, e => locales.findIndex(l => l.code === e.locale))

    strapiChildren.forEach(e => {
        const locale = locales.find(l => l.code === e.locale);
        e.title = locale.name;
    });

    const entity = {
        strapiChildren,
        largeImage: settings?.logo || null,
        Components: [],
        Roles: []
    };

    const selectedModule = settings?.defaultModule?.name || 'material';

    const viewProps = {
        entity,
        selectedModule,
        config: {},
        showBackbutton: false,
        fullscreenMode: false
    }

    const ViewModule = getViewModule(selectedModule);

    useEffect(() => {
        if (typeof window !== 'undefined' && settings?.startpage !== 'languageSelection')
            navigate('/entity/root.html', {replace: true});

    }, [settings]);

    return settings?.startpage === 'languageSelection' &&
        <SettingsContext.Provider value={{settings}}>
            <ViewModule  {...props} {...viewProps} strapiChildren={strapiChildren}/>
        </SettingsContext.Provider>;
};

export default LangSelPage;

export const query = graphql`
    query {
        allStrapiEntities(filter: {identifier: {eq: "root"}}) {
            nodes {
              id
              identifier
              locale
              title
              smallImage {
                  localFile {
                      childImageSharp {
                          gatsbyImageData(width: 500)
                      }
                  }
               }
               Roles
            }
              
        }
        allStrapiAppSettings {
            nodes {
               Topbar {
                    entities {
                        identifier
                        title
                        locale
                    }
                    linkToStartpage
                }
                logo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
                startpage
                defaultModule {
                    name
                }
                menu {
                    themeSwitch
                    textSizeSwitch
                    menuLogo {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
               }
            }
        }
        allStrapiI18NLocale(sort: {fields: id, order: ASC}) {
            nodes {
                name
                code
                isDefault
            }
        }
    }
`;

